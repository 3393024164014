import Denominate from "components/Denominate";
import React from "react";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { useContext, useDispatch } from "context";
import { MenuIcon } from "@heroicons/react/outline";

const Navbar = () => {
  const { loggedIn, dapp, address, account } = useContext();
  
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch({ type: "logout", provider: dapp.provider });
  };

  const handleToggle = () => {
    dispatch({ type: "toggleSidebar" })
  }

  return (
    <div className="navbar">
      <div className="container-fluid pl-0-r pr-0-r mt-1-r mb-md-1-r">
        <div className="d-flex align-items-center mb-0-5-r mb-xl-0-r mr-1-5-r">
          <MenuIcon className="icon-32 mr-1-5-r mt-0-5-r d-none d-lg-block" onClick={handleToggle} /> 
          <Logo className="logo flex-shrink-0 mt-0-5-r" />
          <span className="tagline text-nowrap mb-0-r p-0-r pl-1-5-r ml-1-5-r border-left d-none d-sm-inline">
            Delegation Manager
          </span>
        </div>

        {loggedIn && (
          <div className="d-flex align-items-center flex-wrap justify-content-sm-between justify-content-xl-end mb-sm-1-r mb-md-0-r flex-fill" style={{ minWidth: 0 }}>
            <div className="d-flex flex-column navbar-data mb-1-r mb-sm-0-r mr-1-r text-truncate">
              <div>
                <small className="d-none d-md-inline text-muted mr-0-5-r">
                  Balance:
                </small>
                <small className="text-body mr-0-5-r">
                  <Denominate value={account.balance.toString()} />
                </small>
              </div>
              <div className="text-truncate">
                <small className="d-none d-md-inline text-muted mr-0-5-r">
                  Wallet address:
                </small>
                <small className="text-body text-truncate">{address}</small>
              </div>
            </div>
            <a
              href="/#"
              onClick={logOut}
              className="btn-light btn-small text-decoration-none mb-1-r mb-sm-0-r"
            >
              Sign out
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
