import React, { useEffect, useState } from 'react';
import { useContext, useDispatch } from 'context';
import Delegation from './Delegation';
import PendingUndelegated from './PendingUndelegated';
import NetworkStats from './NetworkStats';
import StakingCalculator from './StakingCalculator';
import { Redirect } from 'react-router-dom';
import Overview from 'components/Overview';
import { Address } from '@elrondnetwork/erdjs';
import { AccountType } from 'helpers/contractDataDefinitions';
import { getItem } from 'storage/session';
import Spinner from 'components/Spinner';

const Dashboard = () => {
  const tabs = [ "My Stake", "Staking Calculator"];
  const [tabName, setTabName] = useState(tabs[0]);
  
  const {
    loggedIn,
    dapp,
    address,
    networkConfig,
    ledgerAccount,
    walletConnectAccount,
  } = useContext();
  const dispatch = useDispatch();

  const fetchAccount = () => {
    if (loggedIn) {
      dapp.proxy.getAccount(new Address(address)).then(account => {
        dispatch({
          type: 'setAccount',
          account: new AccountType(account.balance.toString(), account.nonce),
        });
      });
    }
  };

  const isLedgerLogin = getItem('ledgerLogin') && !ledgerAccount;
  const isWalletConnect = getItem('walletConnectLogin') && !walletConnectAccount;
  const dispatchLoginType = () => {
    if (isLedgerLogin) {
      const ledgerLogin = getItem('ledgerLogin');
      dispatch({
        type: 'setLedgerAccount',
        ledgerAccount: {
          index: ledgerLogin.index,
          address: address,
        },
      });
    }
    if (isWalletConnect) {
      dispatch({
        type: 'setWalletConnectAccount',
        walletConnectAccount: address,
      });
    }
  };
  useEffect(fetchAccount, /* eslint-disable react-hooks/exhaustive-deps */ []);

  useEffect(dispatchLoginType, /* eslint-disable react-hooks/exhaustive-deps */ []);
  if (!loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="dashboard  pl-1-r pr-1-r">
      <div className="card p-1-5-r border-0 mb-1-5-r">
        <Overview />
      </div>
      <div className="row">
        <div className="col-lg-7 mb-1-5-r mb-lg-0-r">
          <div className="card h-100">  
            {networkConfig.roundDuration === -1 ? (
              <Spinner />
            ) : (
              <div className="h-100 d-flex flex-column">
                <div className="p-1-5-r d-flex">
                  {tabs.map((tab, i: number) => (
                    <button key={i} className={`tab mr-0-5-r ${tab === tabName ? "active" : ""} `} onClick={ () => setTabName(tab)}>
                      {tab}
                    </button>
                  ))}
                </div>
                <div className="flex-fill"> 
                  { tabName === "My Stake" && (
                    <div className="h-100 d-flex flex-column">
                      <Delegation/>
                      <PendingUndelegated />
                    </div>
                  )}
                  { tabName === "Staking Calculator" && <StakingCalculator />}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-5">
          <NetworkStats />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
