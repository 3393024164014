import React from "react";

const Spinner = () => {
  return (
    <div className="preloader_wrap preloader_circle">
      <div className="preloader_circ1"></div>
      <div className="preloader_circ2"></div>
      <div className="preloader_circ3"></div>
      <div className="preloader_circ4"></div>
    </div>
  );
};

export default Spinner;
