import * as React from "react";
import { ReactComponent as LedgerLogo } from "assets/images/ledger-nano.svg";
import { ArrowSmLeftIcon } from "@heroicons/react/outline"

const LedgerConnect = ({
  onClick,
  error,
}: {
  onClick: () => void;
  error: string;
}) => {
  const [ledgerError] = React.useState(error);

  const ref = React.useRef(null);

  return (
    <main className="home-wrapper">
      <div
        ref={ref}
        className="home d-flex flex-fill h-100 justify-content-end flex-column flex-md-row"
      >
        <div className="home-inner d-flex align-items-center">
          <div className="m-auto login-container">
            <div className="card text-center mb-1-5-r">
              <div className="card-body p-spacer mx-lg-spacer">
                <LedgerLogo className=" mb-spacer" />
                <h4 className="mb-spacer">Connect Ledger</h4>
                <p className="lead mb-spacer">
                  Unlock your device &amp; open the Elrond App.
                </p>
                <div>
                  {ledgerError && (
                    <p className="text-danger d-flex justify-content-center align-items-center">
                      {ledgerError}
                    </p>
                  )}
                  <button
                    className="btn btn-primary px-spacer"
                    onClick={onClick}
                    data-testid="connectBtn"
                  >
                    Connect Ledger
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <a
                href={process.env.PUBLIC_URL + "/"}
                className="d-flex"
              >
                <ArrowSmLeftIcon className="icon-24" />
                <span className=" lh-1-5-r d-inline-block">Back to Login</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LedgerConnect;
