import React, { useEffect } from 'react';
import { useContext, useDispatch } from 'context';
import { Redirect } from 'react-router-dom';
import { Address } from '@elrondnetwork/erdjs';
import { AccountType } from 'helpers/contractDataDefinitions';
import WalletOverview from './WalletOverview';
import AccountTransactions from './AccountTransactions';

const Dashboard = () => {
  const {
    loggedIn,
    dapp,
    address,
  } = useContext();
  const dispatch = useDispatch();

  const fetchAccount = () => {
    if (loggedIn) {
      dapp.proxy.getAccount(new Address(address)).then(account => {
        dispatch({
          type: 'setAccount',
          account: new AccountType(account.balance.toString(), account.nonce),
        });
      });
    }
  };

  useEffect(fetchAccount, /* eslint-disable react-hooks/exhaustive-deps */ []);
  if (!loggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <div className="dashboard  pl-1-r pr-1-r">
      <WalletOverview />
      <AccountTransactions />
    </div>
  );
};

export default Dashboard;
