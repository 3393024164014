import * as React from "react";
import { decimals, denomination } from "config";
import { useContext } from "context";
import denominate from "components/Denominate/formatters";
import StatCard from "components/StatCard";
import { NetworkStake } from "@elrondnetwork/erdjs";
import { useState } from "react";

const WalletOverview = () => {
  const { dapp, egldLabel, totalActiveStake, numberOfActiveNodes, account } =
    useContext();
  const [networkStake, setNetworkStake] = useState(new NetworkStake());

  const getPercentage = (amountOutOfTotal: string, total: string) => {
    let percentage =
      (parseInt(amountOutOfTotal.replace(/,/g, "")) /
        parseInt(total.replace(/,/g, ""))) *
      100;
    if (percentage < 1) {
      return "<1";
    }
    return percentage ? percentage.toFixed(2) : "...";
  };

  const getNetworkStake = () => {
    dapp.apiProvider
      .getNetworkStake()
      .then((value) => {
        setNetworkStake(value);
      })
      .catch((e) => {
        console.error("getTotalStake error ", e);
      });
  };

  React.useEffect(
    () => {
      getNetworkStake();
    },
    /* eslint-disable react-hooks/exhaustive-deps */ []
  );

  return (
    <div className="row d-flex flex-fill">
      <div className="col-sm-6 col-md-4 mb-1-r mb-1-r">
        <StatCard
          title="Total"
          color="white"
          value={denominate({
            input: totalActiveStake,
            denomination,
            decimals,
          })}
          valueUnit={egldLabel}
          svg="contract.svg"
          percentage={`${getPercentage(
            denominate({
              input: totalActiveStake,
              denomination,
              decimals,
            }),
            denominate({
              input: networkStake.TotalStaked.toFixed(),
              denomination,
              decimals,
            })
          )}% of total stake`}
        />
      </div>

      <div className="col-sm-6 col-md-4 mb-1-r">
        <StatCard
          title="Available"
          color="white"
          value={denominate({
            input: account.balance.toString(),
            denomination,
            decimals,
          })}
          valueUnit={egldLabel}
          svg="user.svg"
          percentage={`${getPercentage(
            denominate({
              input: totalActiveStake,
              denomination,
              decimals,
            }),
            denominate({
              input: networkStake.TotalStaked.toFixed(),
              denomination,
              decimals,
            })
          )}% of total stake`}
        />
      </div>

      <div className="col-sm-6 col-md-4 mb-1-r">
        <StatCard
          title="Stake delegation"
          value={numberOfActiveNodes}
          valueUnit=""
          color="white"
          svg="nodes.svg"
          percentage={`${getPercentage(
            numberOfActiveNodes,
            networkStake.TotalValidators.toString()
          )}% of total nodes`}
        />
      </div>
    </div>
  );
};

export default WalletOverview;
