interface ContinueAndCloseButtonsType {
  actionTitle: string;
  handleContinue: () => void;
  handleClose: () => void;
}

const ContinueAndCloseButtons = ({
  actionTitle,
  handleContinue,
  handleClose,
}: ContinueAndCloseButtonsType) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center flex-wrap">
      <button
        onClick={() => {
          handleContinue();
        }}
        className="btn btn-small mb-1-5-r"
      >
        {actionTitle}
      </button>
      <button id="closeButton" className="btn btn-plain" onClick={handleClose}>
        Close
      </button>
    </div>
  );
};

export default ContinueAndCloseButtons;
