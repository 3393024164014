import { useContext } from "context";
import React, { useState } from "react";
import denominate from "components/Denominate/formatters";

const StakingCalculator = () => {
  const { aprPercentage, egldPrices, egldLabel } = useContext();
  const [value, setValue] = useState("0");

  const lastPrice = egldPrices && egldPrices.length - 1;
  const currentPrice =
    egldPrices && lastPrice && lastPrice > -1 ? egldPrices[lastPrice].value : 0;

  const yearlyGainEgld = Number(value) * Number(aprPercentage) / 100;
  const monthlyGainEgld = (Number(value) * Number(aprPercentage) / 100) / 12;
  const daylyGainEgld = (Number(value) * Number(aprPercentage) / 100) / 365;

  const yearlyGainUsd = yearlyGainEgld * Number(currentPrice);
  const monthlyGainUsd = monthlyGainEgld * Number(currentPrice);
  const daylyGainUsd = daylyGainEgld * Number(currentPrice);

  return (
    <div className="p-1-5-r">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <span className="mb-0-5-r opacity-6 text-body">
                Your Elrond eGold (EGLD) stake
              </span>
              <span className="h5">
                {denominate({
                  input: value.toString(),
                  denomination: 0,
                  decimals: 0,
                })}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="mb-0-5-r opacity-6 text-body">
                Yearly Return
              </span>
              <span className="h5">~ 15-19%</span>
            </div>
          </div>

          <input
            value={value}
            type="range"
            className="custom-range mt-1-5-r"
            min="0"
            max="5000"
            onChange={(e) => setValue(e.target.value)}
          />

          <div className="mt-1-5-r">
            <div className="border-bottom">
              <div>
                <span className="opacity-6 text-body pb-0-5-r">Daily</span>
              </div>
              <div className="d-flex justify-content-between mb-1-r">
                <span>
                  {daylyGainEgld.toFixed(2)} {egldLabel}
                </span>
                <span>${daylyGainUsd.toFixed(2)}</span>
              </div>
            </div>

            <div className="border-bottom mt-1-5-r">
              <div>
                <span className="opacity-6 text-body pb-0-5-r">Monthly</span>
              </div>
              <div className="d-flex justify-content-between mb-1-r">
                <span>
                  {monthlyGainEgld.toFixed(2)} {egldLabel}
                </span>
                <span>${monthlyGainUsd.toFixed(2)}</span>
              </div>
            </div>

            <div className="border-bottom mt-1-5-r">
              <div>
                <span className="opacity-6 text-body pb-0-5-r">Yearly</span>
              </div>
              <div className="d-flex justify-content-between mb-1-r">
                <span>
                  {yearlyGainEgld.toFixed(2)} {egldLabel}
                </span>
                <span>${yearlyGainUsd.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingCalculator;
