import * as React from "react";
import { useContext, useDispatch } from "context";
import denominate from "components/Denominate/formatters";
import DelegateAction from "../Actions/DelegateAction";
import UndelegateAction from "../Actions/UndelegateAction";
import { contractViews } from "contracts/ContractViews";
import ClaimRewardsAction from "../Actions/ClaimRewardsAction";
import State from "components/State";
import { denomination, decimals } from "config";
import { decodeBigNumber, decodeUnsignedNumber } from "@elrondnetwork/erdjs";
import Spinner from "components/Spinner";

const MyDelegation = () => {
  const { dapp, address, egldLabel, delegationContract, loading } =
    useContext();
  const dispatch = useDispatch();
  const { getClaimableRewards, getUserActiveStake } = contractViews;
  const [userActiveStake, setUserActiveStake] = React.useState("0");
  const [userActiveNominatedStake, setUserActiveNominatedStake] =
    React.useState("0");
  const [claimableRewards, setClaimableRewards] = React.useState("0");
  const [displayRewards, setDisplayRewards] = React.useState(false);
  const [displayUndelegate, setDisplayUndelegate] = React.useState(false);

  const getAllData = () => {
    dispatch({ type: "loading", loading: true });
    getClaimableRewards(dapp, address, delegationContract)
      .then((value) => {
        const untypedResponse = value.outputUntyped();
        if (
          untypedResponse.length > 0 &&
          decodeUnsignedNumber(untypedResponse[0]) !== 0
        ) {
          setDisplayRewards(true);
        }
        setClaimableRewards(
          denominate({
            denomination,
            decimals: 4,
            input: decodeBigNumber(untypedResponse[0]).toFixed(),
          }) || ""
        );
      })
      .catch((e) => console.error("getClaimableRewards error", e));
    getUserActiveStake(dapp, address, delegationContract)
      .then((value) => {
        const untypedResponse = value.outputUntyped();
        setUserActiveStake(
          denominate({
            denomination,
            decimals,
            input: decodeBigNumber(untypedResponse[0]).toFixed(),
          }) || ""
        );
        setUserActiveNominatedStake(
          decodeBigNumber(untypedResponse[0]).toFixed()
        );
        if (
          untypedResponse.length > 0 &&
          decodeUnsignedNumber(untypedResponse[0]) !== 0
        ) {
          setDisplayUndelegate(true);
        }

        dispatch({ type: "loading", loading: false });
      })
      .catch((e) => {
        console.error("getUserActiveStake error", e);
        dispatch({
          type: "loading",
          loading: false,
        });
      });
  };

  React.useEffect(
    getAllData,
    /* eslint-disable react-hooks/exhaustive-deps */ []
  );

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex-fill d-flex flex-column">
          {userActiveStake !== String(0) && (
            <div className="bg-light d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-md-end p-1-5-r pr-1-5-r pl-1-5-r">
              <DelegateAction />
              {displayUndelegate && (
                <UndelegateAction balance={userActiveNominatedStake} />
              )}
            </div>
          )}
          <div className="flex-fill justify-content-center align-items-center d-flex pb-1-5-r pb-lg-0-r pt-1-5-r pt-lg-0-r pl-1-5-r pr-1-5-r">
            <div className="text-center">
              {userActiveStake === String(0) ? (
                <State
                  title="No Stake Yet"
                  description="Welcome to our platform!"
                  action={<DelegateAction />}
                />
              ) : (
                <div>
                  <p className="m-0-r">Active Delegation</p>
                  <p className="h4">
                    {userActiveStake} {egldLabel}
                  </p>
                </div>
              )}
              <p className="text-muted mb-0-r">
                {claimableRewards} {egldLabel} Claimable rewards
              </p>
              {displayRewards ? <ClaimRewardsAction /> : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyDelegation;
