import * as React from "react";
import { network } from "config";
import { useContext } from "context";
import { useState } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import moment from "moment";
import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import denominate from "components/Denominate/formatters";
import Spinner from "components/Spinner";

const AccountTransactions = () => {
  const { egldLabel, address } = useContext();
  const [transactions, setTransactions] = useState([]);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [listSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const numberOfPages = Math.ceil(transactionsCount / listSize);

  const getAccountTransactions = async (address: string) => {
    setLoading(true);
    const searchParams = new URLSearchParams({
      sender: address,
      receiver: address,
      condition: "should",
      from: ((currentPage - 1) * listSize).toString(),
      size: listSize.toString(),
    });
    const result = await axios.get(
      `${network.apiAddress}/transactions/?${searchParams}`
    );
    if (result.status) {
      setTransactions(result.data);
    }
    setLoading(false);
  };

  const getAccountTransactionsCount = async (address: string) => {
    const searchParams = new URLSearchParams({
      sender: address,
      receiver: address,
      condition: "should"
    });
    const result = await axios.get(
      `${network.apiAddress}/transactions/count/?${searchParams}`
    );
    if (result.status) {
      setTransactionsCount(result.data);
    }
  };

  React.useEffect(
    () => {
      getAccountTransactions(address);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage]
  );

  React.useEffect(
    () => {
      getAccountTransactionsCount(address);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage]
  );
  
  return (
    <div className="card">
      <span className="h6 p-1-5-r">Transactions</span>
      {loading ? (
        <Spinner />
      ) : (
      <Table hover responsive className="transactions-table mb-0-r">
        <tbody>
          {transactions.map((t: any, i: number) =>
            t.sender === address ? (
              <tr className="border-bottom" key={i}>
                <td className="d-flex align-items-center border-0">
                  <ArrowCircleUpIcon className="icon-32 mr-1-r text-black-50" />
                  <div>
                    <span className="opacity-6 text-body">Sent to </span>
                    {t.sender}
                    <div className="text-sm opacity-6 text-body">
                      {moment(t.timestamp).format("MMMM Do YYYY, h:mm A")}
                    </div>
                  </div>
                </td>

                <td className="border-0">
                  <div className="d-flex flex-column">
                    <div className="text-break">
                      <span className="opacity-6 text-body">Action: </span>
                      {t.data && (<code className="text-black-50">{window.atob(t.data)}</code>)}
                    </div>
                    <div>
                      <span className="opacity-6 text-body">
                        Transaction Fee:{" "}
                      </span>
                      {denominate({
                        input: t.fee.toString(),
                        denomination: 18,
                        decimals: 8,
                      })}
                    </div>
                  </div>
                </td>

                <td className="border-0">
                  <div key={t.txHash}>
                    {t.value !== "0" && "-"}
                    <span>
                      {denominate({
                        input: t.value.toString(),
                        denomination: 18,
                        decimals: 2,
                      })}{" "}
                      {egldLabel}
                    </span>
                  </div>
                </td>
              </tr>
            ) : t.receiver === address ? (
              <tr className="border-bottom" key={i}>
                <td className="d-flex align-items-center border-0">
                  <ArrowCircleDownIcon className="icon-32 mr-1-r text-black-50" />
                  <div>
                    <span className="opacity-6 text-body">Recived from </span>
                    {t.sender}
                    <div className="text-sm opacity-6 text-body">
                      {moment(t.timestamp).format(" MMMM Do YYYY, h:mm A")}
                    </div>
                  </div>
                </td>

                <td className="border-0">
                  <div className="d-flex flex-column">
                    <div>
                      <span className="opacity-6 text-body">
                        Transaction Fee:{" "}
                      </span>
                      {denominate({
                        input: t.fee.toString(),
                        denomination: 18,
                        decimals: 8,
                      })}
                    </div>
                  </div>
                </td>

                <td className="border-0">
                  <div key={t.txHash} className="text-success">
                    {t.value !== "0" && "+"}
                    <span>
                      {denominate({
                        input: t.value.toString(),
                        denomination: 18,
                        decimals: 2,
                      })}{" "}
                      {egldLabel}
                    </span>
                  </div>
                </td>
              </tr>
            ) : (
              <div></div>
            )
          )}
        </tbody>
      </Table>)}

      <div className="d-flex justify-content-between m-2-r">
        <button className="btn btn-plain d-flex align-items-center" disabled={currentPage < 2 } onClick={() => {
          setCurrentPage(currentPage - 1);
        }}>
          <ChevronLeftIcon className="icon-16 text-muted mr-0-5-r"/>
          Prev
        </button>
        <div>
          <span>Page { currentPage } of { numberOfPages }</span>
        </div>
        <button className="btn btn-plain d-flex align-items-center" disabled={ currentPage > numberOfPages - 1 } onClick={() => {
          setCurrentPage(currentPage + 1);
        }}>
          Next
          <ChevronRightIcon className="icon-16 text-muted ml-0-5-r" />
        </button>
      </div>
    </div>
  );
};

export default AccountTransactions;
