import SVG from 'react-inlinesvg';
import { StatCardType, ShadowSize } from 'helpers/types';
import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const StatCard = ({
  title = '',
  value = '0',
  valueUnit = '',
  color = '',
  svg = '',
  percentage = '',
  tooltipText = '',
  children = null,
  border = false,
  shadow = ShadowSize.SM
}: StatCardType) => {
  return (
    <div className={`statcard card-bg-${color} text-white py-3 px-4 rounded ${border ? 'border' : ''} shadow-${shadow}`}>
      { svg !== '' && (
        <div className="d-flex align-items-center justify-content-between mt-1 mb-2"> 
          <div className="icon my-1 fill-primary">
            <SVG src={process.env.PUBLIC_URL + '/' + svg} className="text-primary"></SVG>
          </div>
          <div>{children}</div>
        </div>
      )}      
      <span className="opacity-6 text-body">{title}</span>
      <p className="h5 mb-0 text-body">
        {value} {valueUnit}
      </p>
      <small className="opacity-5 text-body">
        {percentage}
        {tooltipText !== '' && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={props => (
              <Tooltip id="button-tooltip" {...props}>
                {tooltipText}
              </Tooltip>
            )}
          >
            <InformationCircleIcon style={{width: '14px', marginBottom: '3px', marginLeft: '3px'}} className="text-body" />
          </OverlayTrigger>
        )}
      </small>
    </div>
  );
};

export default StatCard;
