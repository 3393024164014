import React from 'react';
import { Redirect } from 'react-router-dom';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import State from 'components/State';
import { useContext } from 'context';
import WalletLogin from './Login/Wallet';
import WalletConnectLogin from './Login/WalletConnect';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import Spinner from 'components/Spinner';

const Home = () => {
  const { loading, error, loggedIn, egldLabel } = useContext();

  const ref = React.useRef(null);

  return (
    <main className="home-wrapper">
      <div ref={ref} className="home d-flex flex-fill h-100 justify-content-end flex-column flex-md-row">
        <div className="home-inner d-flex align-items-center">
          {error ? (
            <State
              icon={faBan}
              iconClass="text-primary"
              title="Something went wrong"
              description="If the problem persists please contact support."
            />
          ) : loggedIn ? (
            <Redirect to="/dashboard" />
          ) : loading ? (
            <Spinner />
          ) : (
            <div className="m-auto login-container">
                  <Logo className="mb-2-r" style={{maxHeight: "86px"}} />
                  <h5 className="mb-0">Delegation Manager</h5>
                  <p className="lead mb-spacer">
                    Delegate Elrond ({egldLabel}) and earn up to 20% APY!
                  </p>
                  <p className="mb-spacer">Please select your login method:</p>
                  <div>
                    <a
                      href={process.env.PUBLIC_URL + '/ledger'}
                      className="btn btn-primary mr-sm-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
                    >
                      Ledger
                    </a>
                    <WalletLogin />
                    <WalletConnectLogin />
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default Home;
