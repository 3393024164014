import StatCard from "components/StatCard";
import { useContext } from "context";
import { Line } from "react-chartjs-2";
import denominate from 'components/Denominate/formatters';
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/outline";

const NetworkStatsView = () => {
  const { networkStats, egldPrices } = useContext();

  const dataFormatter = egldPrices?.reduce(
    (acc, item) => {
      const date = new Date(item.time);
      acc.labels.push(
        `${date.toLocaleString("default", {
          month: "short",
        })} ${date.getDate()}`
      );
      acc.values.push(item.value.toString());
      return acc;
    },
    { labels: [] as string[], values: [] as string[] }
  );

  const chartData = {
    labels: dataFormatter?.labels,
    datasets: [
      {
        type: "line",
        label: "USD",
        borderColor: "#2233ff",
        data: dataFormatter?.values,
        tension: 0.4,
        legend: {
          display: false,
        },
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.38,
      },
    },
    scales: {
      x: {
        ticks: {
          fontColor: "#212529",
          stepSize: 3,
        },
        maxTicksLimit: 10,
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          stepSize: 25,
          fontColor: "#212529",
        },
        grid: {
          color: "rgba(0,0,0,0.1)",
        },
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
  };

  const lastPrice = dataFormatter?.values[dataFormatter?.values.length - 2]
  const currentPrice = dataFormatter?.values[dataFormatter?.values.length - 1]
  const diffPrices =  Number(currentPrice) / Number(lastPrice) * 100;

  return (
    <>
      <div className="card h-100 p-1-5-r">
        <div>
          <p className="h6 mb-3">Network stats</p>
        </div>

        <div className="form-row mb-1-5-r">
          <div className="col-sm-4 col-lg-12 col-xl-4 mb-1-r mb-sm-0-r mb-lg-1-r">
            <StatCard
              title="Accounts"
              value={denominate({
                input: networkStats?.Accounts.toString() || "0",
                denomination: 0,
                decimals: 0
              })}
              color="white"
            />
          </div>
          <div className="col-sm-4 col-lg-12 col-xl-4 mb-1-r mb-sm-0-r mb-lg-1-r">
            <StatCard
              title="Transactions"
              value={denominate({
                input: networkStats?.Transactions.toString() || "0",
                denomination: 0,
                decimals: 0
              })}
              color="white"
            />
          </div>
          <div className="col-sm-4 col-lg-12 col-xl-4 mb-1-r mb-sm-0-r mb-lg-1-r">
            <StatCard
              title="Blocks"
              value={denominate({
                input: networkStats?.Blocks.toString() || "0",
                denomination: 0,
                decimals: 0
              })}
              color="white"
            />
          </div>
        </div>

        <div className="d-flex align-items-center lh-1-r mb-1-r">
          <span>1 EGLD = { egldPrices && ('$'+currentPrice) }</span>
          { (diffPrices >= 100) 
            ? (<span className="pl-0-5-r text-success">
                { (diffPrices > 100) && (<ArrowSmUpIcon className="icon-16 " />)}
                <span> 
                  { denominate({
                    input: (diffPrices - 100).toString() ,
                    denomination: 1,
                    decimals: 2,
                    }) 
                  } %
                </span> 
              </span>)
            : (<span className="pl-0-5-r text-danger"> 
                <ArrowSmDownIcon className="icon-16" />
                <span> -   
                  { denominate({
                    input: (100 - diffPrices).toString() ,
                    denomination: 1,
                    decimals: 2,
                    }) 
                  } %
                </span>
              </span>)
          }
        </div>

        <div className="prices-chart">
          <Line data={chartData} options={chartOptions} />
        </div>
      </div>
    </>
  );
};

export default NetworkStatsView;
