import React from "react";
import { NavLink } from "react-router-dom";
import {
  LockClosedIcon,
  CashIcon,
} from "@heroicons/react/outline";

const Navbar = () => {
  return (
    <div className="sidebar">
      <div className="main-menu">
        <ul>
          <li>
            <NavLink to="/dashboard">
              <LockClosedIcon className="icon-24" />
              <span>Delegation</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/wallet">
              <CashIcon className="icon-24" />
              <span>Wallet</span>
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="bottom-links"></div>
    </div>
  );
};

export default Navbar;
